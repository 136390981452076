import React from 'react'

const RIdata = () => {
  return (
    <div>
        <h1> Receipt Invoice Data..</h1>
        <h3>will be shown here..</h3>
      
    </div>
  )
}

export default RIdata
