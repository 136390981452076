export enum ROUTES {
  home = '/',
  login = '/login',
  // sign_up = '/sign-up',
  // manage = '/manage',
  // expenseReport = '/expense-report',
  // expenseDetails = 'expense-details',
  settings = '/settings',
  termsOfService = '/settings/terms-of-service',
  privacyPolicy = '/settings/privacy-policy',
  usersList = '/settings/users-list',
  // companiesList = '/settings/companies-list',
  // support = '/support',
  // preference = '/preference',
  forgotPassword = '/forgot-password',
  resetPassword = '/reset-password/:token',
  // signUpNewMember = '/signup-new-member/:token',
  // bindSocialAccount = '/bind-social-account/:token',
  // chooseCompany = '/choose-company',
  // capiumLogin = '/capium-login',
  profile = '/profile',
  invites = '/invites',
  callback = '/callback',
  pendingriData = '/pending-ridata',
  // purchaseInvoices = '/purchase-invoices',
  // receiptDetails = 'receipt-details',
  // salesInvoices = '/sales-invoices',
  // invoiceDetails = '/invoice-details',
  // receiptUploadFile = '/purchase-invoices/upload-file',
  // invoiceUploadFile = '/sales-invoices/upload-file',
  // filesUploadPreview = '/inbox/files-upload-preview',
  // filesUploadPreviewsales = '/sales-invoices/files-upload-preview',
  // setting/permission
  // permission = '/settings/permission',
  notFound = '*',
}
